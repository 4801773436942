<template>
	<!-- <v-sheet class="" style="height: calc(100vh - 84px)"> -->
	<div>
		<v-layout class="flex-wrap">
			<div class="pt-3 d-flex align-start justify-space-between w-100">
				<!-- <h4 class="fw-500 title-text mr-4 mt-0" style="white-space: nowrap;margin:top:0px">
					Domain
				</h4> -->
				<v-flex class="custom">
					<v-select
						v-model="status"
						solo
						class="listing-select"
						hide-details
						:items="projectStatusList"
						@change="statusFilterRows"
						item-text="text"
						item-value="value"
						style="max-width: 270px"
						:menu-props="{ offsetY: true, bottom: true }"
					>
						<template #item="{ item }">
							<div class="d-flex py-1 align-center">
								<div class="mr-3">
									<v-chip
										class="d-flex align-center justify-center px-1 py-1"
										style="min-width: 30px"
										:color="item.status_color"
										dark
									>
										{{ item.count }}
									</v-chip>
								</div>
								<div class="fw-500">
									{{ item.text }}
								</div>
							</div>

							<!-- <v-list-item-action>
								<v-avatar
									style="
										height: 26px !important;
										min-width: 26px !important;
										width: auto !important;
										max-width: auto !important;
									"
									:color="item.status_color"
								>
									<span class="white--text">{{ item.count }}</span>
								</v-avatar>
							</v-list-item-action>
							<v-list-item-content>
								<v-list-item-title v-text="item.text"></v-list-item-title>
								<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
							</v-list-item-content> -->
						</template>
					</v-select>
				</v-flex>
				<v-spacer></v-spacer>
				<div class="d-flex align-center">
					<div class="fw-600 mr-2">Filter By</div>
					<!-- Entity -->
					<v-select
						v-if="currentUser.users_type != 'seo'"
						style="width: 250px"
						placeholder="Select Entity"
						outlined
						hide-details
						:items="entityList"
						v-model="customFilter.entity"
						@change="statusFilterRows"
						:menu-props="{ offsetY: true, bottom: true }"
						clearable
					>
						<template #item="{ item }">
							<div class="py-1">
								<v-avatar size="35">
									<ImageTemplate
										style="max-width: 30px; height: 30px; box-shadow: 0px 0px 2px 0px #888"
										circle
										:src="`media/logos/${item.value}.png`"
									></ImageTemplate>
								</v-avatar>
								<span class="ml-3 fw-500">{{ item.text }}</span>
							</div>
						</template>
					</v-select>

					<div class="ml-2">
						<!-- Sales Person customFilter.user_filter-->
						<v-autocomplete
							:items="usersList"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							v-if="currentUser.users_type != 'seo'"
							clearable
							height="30px"
							v-model="customFilter.user_filter"
							@change="statusFilterRows"
							style="min-width: 300px !important; max-width: 300px !important"
							hide-details
							item-text="display_name"
							item-value="id"
							placeholder="Select Sales Person"
						>
							<template #selection="data">
								<v-chip small v-bind="data.attrs" :input-value="data.selected">
									<v-avatar left>
										<v-img
											v-if="getValue(data, 'item.profile_img')"
											:src="getValue(data, 'item.profile_img')"
										></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
									{{ getValue(data, "item.display_name") }}
								</v-chip>
							</template>
							<template #item="{ item }">
								<div class="py-1 d-flex align-center">
									<v-avatar size="36" class="mr-3">
										<img v-if="getValue(item, 'profile_img')" :src="getValue(item, 'profile_img')" />
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
									<div>
										<div class="text-uppercase fw-500">{{ getValue(item, "display_name") }}</div>
										<div class="text-muted">{{ getValue(item, "email") }}</div>
									</div>
								</div>
							</template>
						</v-autocomplete>
					</div>

					<div class="ml-2">
						<!-- SEO Team customFilter.seo_team -->
						<v-autocomplete
							:items="seoTeam"
							:menu-props="{ bottom: true, offsetY: true }"
							outlined
							v-if="
								currentUser.users_type == 'seo' ||
								AllowAnyOforGetRoleNType(['master', 'admin', 'manager', 'super_admin'])
							"
							clearable
							height="30px"
							v-model="customFilter.seo_team"
							@change="statusFilterRows"
							style="min-width: 300px !important; max-width: 300px !important"
							hide-details
							item-text="display_name"
							item-value="id"
							placeholder="Select SEO Team"
						>
							<template #selection="data">
								<v-chip small v-bind="data.attrs" :input-value="data.selected">
									<v-avatar left>
										<v-img
											v-if="getValue(data, 'item.profile_img')"
											:src="getValue(data, 'item.profile_img')"
										></v-img>
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
									{{ getValue(data, "item.display_name") }}
								</v-chip>
							</template>
							<template #item="{ item }">
								<div class="w-100 py-1 d-flex align-center">
									<v-avatar size="36" class="mr-3">
										<img v-if="getValue(item, 'profile_img')" :src="getValue(item, 'profile_img')" />
										<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
									</v-avatar>
									<v-spacer></v-spacer>
									<div class="w-100 d-flex justify-content-between">
										<div class="text-uppercase fw-500 mr-3">{{ getValue(item, "display_name") }}</div>
										<v-chip x-small outlined color="primary" class="px-1 float-right">{{
											getValue(item, "users_type")
										}}</v-chip>
									</div>
								</div>
							</template>
						</v-autocomplete>
					</div>

					<!-- Category customFilter.category -->
					<div class="mx-2">
						<SelectInput
							:menu-props="{ bottom: true, offsetY: true }"
							hide-details
							clearable
							:items="categoryList"
							item-text="category"
							item-value="value"
							style="margin-top: 0px !important"
							placeholder="Category"
							v-model="customFilter.category"
							@change="statusFilterRows()"
						>
						</SelectInput>
					</div>

					<!-- Date Range customFilter.daterange -->
					<DateRangePicker
						:id="+new Date()"
						hide-details
						hide-top-margin
						clearable
						@click:clear="(customFilter.daterange = null), statusFilterRows()"
						:inline-style="{ maxWidth: '250px', minWidth: '235px' }"
						class="mr-2"
						contentClass="rounded-lg pa-0"
						placeholder="Date Range"
						v-model="customFilter.daterange"
						:disablePastFrom="
							customFilter.daterange && Array.isArray(customFilter.daterange)
								? customFilter.daterange[0]
								: ''
						"
						@change="filterDate"
					></DateRangePicker>

					<v-btn
						depressed
						color="white"
						@click="resetFilters"
						class="red darken-4 mx-2"
						tile
						text
						min-height="30px"
						x-small
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>

					<!-- <v-btn color="blue darken-4 white--text" tile min-height="30px" x-small depressed class="mx-2">
						<v-icon color="white">mdi-magnify</v-icon>
					</v-btn> -->

					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template #activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								min-height="30px"
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
								x-small
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('seo')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('seo')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</div>
			</div>
		</v-layout>
		<div class="mb-2 overflow-auto">
			<v-btn-toggle v-model="toggle_exclusive" mandatory dense tile>
				<v-btn
					class="btn-border"
					:class="[
						{ 'blue darken-4 white--text': customFilter.alphabet == null },
						{ 'blue--text': customFilter.alphabet != null },
					]"
					@click="(customFilter.alphabet = null), statusFilterRows()"
				>
					ALL
				</v-btn>
				<v-btn
					class="btn-border"
					:class="[
						{ 'blue darken-4 white--text': customFilter.alphabet == data },
						{ 'grey lighten-4': customFilter.alphabet != data },
					]"
					:color="customFilter.alphabet != data ? 'grey lighten-4' : 'blue darken-4 '"
					v-for="(data, index) in filter"
					:key="'$_alphabet_' + index"
					@click="(customFilter.alphabet = data), statusFilterRows()"
				>
					{{ data }}
				</v-btn>
			</v-btn-toggle>
		</div>
		<!-- <Table
			v-if="false"
			type="seo"
			delete-endpoint="seo/"
			detail-route="seo-detail"
			update-route="update-seo"
			v-on:reload:content="filterRows"
			:status-filter-data="statusFilterData"
		></Table> -->
		<!-- custom-seo-table -->
		<!-- <div class=""> -->

		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Domain, Target Country, Sales Person, Category"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>

		<template v-if="pageLoading">
			<div class="mx-20 my-40 text-center">
				<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
			</div>
		</template>

		<div v-else class="v-data-table bt-table theme--light">
			<div class="v-data-table__wrapper">
				<table class="w-100">
					<thead>
						<tr>
							<template v-for="(th, key) in allowedSortedThead">
								<th :key="key" :width="th.width">
									<v-layout>
										<v-flex class="text-left">
											<template v-if="th.checkbox">
												<!-- <v-checkbox
													dense
													v-model="checkAll"
													:ripple="false"
													hide-details
													color="blue darken-4"
													class="m-0 p-0"
												></v-checkbox> -->
												Sr No.
											</template>
											<template v-else>
												{{ th.name }}
											</template>
										</v-flex>
										<v-flex v-if="!th.checkbox && th.sortable" class="text-right ml-2">
											<template v-if="th.sort == 'ASC'">
												<v-icon small link>mdi-sort-ascending</v-icon>
											</template>
											<template v-if="th.sort == 'DESC'">
												<v-icon small link>mdi-sort-descending</v-icon>
											</template>
										</v-flex>
									</v-layout>
								</th>
							</template>
						</tr>
					</thead>
					<tbody>
						<template v-if="tbody && tbody.length">
							<tr v-for="(domain, b_key) in tbody" :key="'$_body_' + b_key">
								<template v-for="(th, hkey) in allowedSortedThead">
									<!-- <tr @click="goToSeoProjectDetail" v-for="(domain, index) in seoProjectList" :key="index"> -->
									<td
										@click="goToSeoProjectDetail(th, domain)"
										:key="'head_' + hkey"
										:style="{ minWidth: th.key == 'remark' ? '300px' : 'auto' }"
										:class="{ 'cursor-pointer': !th.checkbox && th.key != 'action' }"
									>
										<template v-if="th.checkbox">
											<div class="fw-500 text-center">
												{{ (+page - 1) * 15 + b_key + 1 }}
											</div>
											<!-- <v-checkbox
												dense
												:ripple="false"
												:value="domain.id"
												hide-details
												v-model="selectedRows"
												multiple
												@change="selectRow"
												class="m-0 p-0"
												color="blue darken-4"
											></v-checkbox> -->
										</template>

										<template v-if="th.key == 'action'">
											<div class="text-center">
												<v-icon
													size="20"
													class="cursor-pointer p-1"
													style="border: 1px solid; border-radius: 15%"
													@click="editSingleData(domain)"
													color="blue"
													v-if="getPermission('seo:update')"
													>mdi-pencil
												</v-icon>
												<!-- <v-icon
													size="20"
													v-if="getPermission('seo:delete')"
													@click="doAction(domain, 'delete')"
													class="cursor-pointer"
													color="red"
												>
													mdi-delete
												</v-icon> -->
											</div>
										</template>

										<template v-if="th.key == 'entity'">
											<div class="d-flex align-center">
												<ImageTemplate
													v-if="domain.company_type == 'genic-solution'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/genic-solution.png"
													circle
													class="mr-2"
												>
												</ImageTemplate>

												<ImageTemplate
													v-if="domain.company_type == 'bthrust'"
													style="max-width: 35px; height: 35px; box-shadow: 0px 0px 1px 0px #888"
													src="media/logos/bthrust.png"
													circle
													class="mr-2"
												></ImageTemplate>

												<em
													v-else-if="!domain.company_type || domain.company_type == 'null'"
													class="text-muted"
												>
													no entity
												</em>
											</div>
										</template>

										<template v-if="th.key == 'webiste/sales'">
											<!-- <ShowValue :object="domain" object-key="urls" label="website"></ShowValue> -->
											<div class="d-flex justify-content-between">
												<div class="mr-1 text--darken-4" style="font-weight: 600; width: 60px">Domain:</div>
												<div>
													<template v-if="domain.urls">
														<!-- <v-chip
															style="height: auto !important"
															class="white--text"
															label
															color="teal lighten-1"
														>
															<span class="text-wrap">{{ domain.urls }}</span>
														</v-chip> -->
														<div
															class="text-truncate fw-600"
															:class="[
																+getValue(domain, 'website_urls.url_status') != 200 ? 'red--text' : 'green--text',
															]"
															style="max-width: 200px"
														>
															{{ domain.urls }}
														</div>
													</template>
													<template v-else>
														<em class="text-muted">no domain name</em>
													</template>
												</div>
											</div>

											<div class="d-flex justify-content-between my-1">
												<div class="mr-3 text--darken-4" style="font-weight: 600; width: 45px">Target:</div>

												<div class="d-flex align-center">
													<div class="vti__flag mr-1" v-if="domain.target_google" :class="target_flag(domain)" />
													<div class="ml-1" v-if="domain.target_google">
														{{ domain.target_google && domain.target_google }}
													</div>
													<template v-else>
														<em>not set</em>
													</template>
												</div>
											</div>

											<div class="d-flex justify-content-between my-1">
												<div class="mr-1 text--darken-4" style="font-weight: 600; width: 45px">Sales:</div>
												<div class="d-flex align-center">
													<!-- <ShowValue :object="domain" object-key="sales" label="sales Person"></ShowValue> -->
													<!-- {{ hasKey(domain, 'sales_owner.profile_img') }} -->
													<!-- {{ getValue(domain, 'sales_owner.profile_img') }} -->
													<ImageTemplate
														v-if="
															hasKey(domain, 'sales_owner.profile_img') &&
															getValue(domain, 'sales_owner.profile_img')
														"
														circle
														style="max-width: 20px"
														:src="getValue(domain, 'sales_user') && getValue(domain, 'sales_user.profile_img')"
													>
													</ImageTemplate>
													<!-- {{ domain.sales_owner[0].profile_img }}  -->
													<div class="ml-4" v-if="getValue(domain, 'sales_user')">
														{{ getValue(domain, "sales_user.display_name") }}
													</div>
													<div v-else>
														<em class="text-muted">no sales owner</em>
													</div>
												</div>
											</div>

											<div class="d-flex justify-content-between">
												<div class="mr-1 text--darken-4" style="font-weight: 600; width: 45px">
													Status code:
												</div>
												<div class="d-flex align-center">
													<div v-if="getValue(domain, 'website_urls.url_status')">
														<v-chip
															x-small
															class="white--text fw-600"
															label
															:color="+getValue(domain, 'website_urls.url_status') != 200 ? 'red' : 'green'"
														>
															{{ getValue(domain, "website_urls.url_status") }}
														</v-chip>
													</div>
													<div v-else>
														<em class="text-muted">no status code</em>
													</div>
												</div>
											</div>
										</template>

										<template v-if="th.key == 'project_details'">
											<div class="d-flex justify-content-between">
												<!-- <div class="mr-1 text--darken-4" style="font-weight: 600; width: 60px">Project:</div> -->
												<v-chip label dark color="cyan">
													{{ getValue(domain, "projectData.barcode") }}
												</v-chip>
											</div>

											<div class="d-flex mt-2 text-capitalize">
												<div class="mr-1 text--darken-4" style="font-weight: 600; width: 60px">SEO Type:</div>
												&nbsp;
												<template v-if="getValue(domain, 'projectData.seo_type')">
													<div class="fw-500 d-flex align-center">
														<v-icon
															left
															color="green"
															size="17"
															v-if="getValue(domain, 'projectData.seo_type') == 'combined'"
															>mdi-hexagon-multiple</v-icon
														>
														<v-icon
															left
															color="blue"
															size="17"
															v-if="getValue(domain, 'projectData.seo_type') == 'single'"
															>mdi-hexagon</v-icon
														>
														{{ getValue(domain, "projectData.seo_type") }}
													</div>
												</template>
												<em v-else class="text-muted">no seo type</em>
											</div>

											<!-- <div class="text-center">
												<v-chip label dark color="cyan">
													{{ getValue(domain, "projectData.barcode") }}
												</v-chip>
											</div> -->
										</template>

										<template v-if="th.key == 'top_3'">
											<div
												v-if="domain.ranking_data || domain.to_customer || domain.to_delhi"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.ranking_data || domain.to_customer">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center align-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_3,
																					domain.to_customer.top_3,
																					domain.ranking_data?.top_3,
																					domain.month_passed
																			  )
																			: '',
																	]"
																	>{{
																		domain.ranking_data
																			? +domain.ranking_data?.top_3 > 0
																				? domain.ranking_data?.top_3
																				: "-"
																			: "-"
																	}}</span
																>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_3 > 0
																					? domain.to_customer.top_3
																					: "-"
																				: domain.to_customer.kpi_6_month_top_3 > 0
																				? domain.to_customer.kpi_6_month_top_3
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>

												<template v-if="domain.ranking_data || domain.to_delhi">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_3,
																					domain.to_customer.top_3,
																					domain.ranking_data.top_3,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_3") &&
																		getValue(domain, "ranking_data.top_3") &&
																		domain.ranking_data.top_3 > 0
																			? domain.ranking_data.top_3
																			: "-"
																	}}</span
																>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		hasKey(domain, "to_delhi")
																			? domain.month_passed > 6
																				? hasKey(domain, "to_delhi.top_3") && domain.to_delhi.top_3 > 0
																					? domain.to_delhi.top_3
																					: "-"
																				: hasKey(domain, "to_delhi.kpi_6_month_top_3") &&
																				  domain.to_delhi.kpi_6_month_top_3 > 0
																				? domain.to_delhi.kpi_6_month_top_3
																				: "-"
																			: "-"
																	}}</span
																>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'top_5'">
											<div
												v-if="domain.ranking_data || domain.to_customer || domain.to_delhi"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.ranking_data || domain.to_customer">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_5,
																					domain.to_customer.top_5,
																					domain.ranking_data.top_5,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_5") &&
																		getValue(domain, "ranking_data.top_5") &&
																		domain.ranking_data.top_5 > 0
																			? domain.ranking_data.top_5
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_5 > 0
																					? domain.to_customer.top_5
																					: "-"
																				: domain.to_customer.kpi_6_month_top_5 > 0
																				? domain.to_customer.kpi_6_month_top_5
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>

												<template v-if="domain.ranking_data || domain.to_delhi">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_5,
																					domain.to_customer.top_5,
																					domain.ranking_data.top_5,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_5") &&
																		getValue(domain, "ranking_data.top_5") &&
																		domain.ranking_data.top_5 > 0
																			? domain.ranking_data.top_5
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_delhi
																			? domain.month_passed > 6
																				? domain.to_delhi.top_5 > 0
																					? domain.to_delhi.top_5
																					: "-"
																				: domain.to_delhi.kpi_6_month_top_5 > 0
																				? domain.to_delhi.kpi_6_month_top_5
																				: "-"
																			: "-"
																	}}
																</span>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'top_10'">
											<div
												v-if="domain.to_customer || domain.to_delhi || domain.ranking_data"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.ranking_data || domain.to_customer">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_10,
																					domain.to_customer.top_10,
																					domain.ranking_data.top_10,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_10") &&
																		getValue(domain, "ranking_data.top_10") &&
																		domain.ranking_data.top_10 > 0
																			? domain.ranking_data.top_10
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_10 > 0
																					? domain.to_customer.top_10
																					: "-"
																				: domain.to_customer.kpi_6_month_top_10 > 0
																				? domain.to_customer.kpi_6_month_top_10
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>
												<div v-else>-</div>

												<template v-if="domain.ranking_data || domain.to_delhi">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<v-chip x-small label color="lime darken-4" outlined v-on="on" v-bind="attrs">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_10,
																					domain.to_customer.top_10,
																					domain.ranking_data.top_10,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_10") &&
																		getValue(domain, "ranking_data.top_10") &&
																		domain.ranking_data.top_10 > 0
																			? domain.ranking_data.top_10
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_delhi
																			? domain.month_passed > 6
																				? domain.to_delhi.top_10 > 0
																					? domain.to_delhi.top_10
																					: "-"
																				: domain.to_delhi.kpi_6_month_top_10 > 0
																				? domain.to_delhi.kpi_6_month_top_10
																				: "-"
																			: "-"
																	}}
																</span>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>
												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'top_20'">
											<div
												v-if="domain.to_customer || domain.to_delhi || domain.ranking_data"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.to_customer || domain.ranking_data">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_20,
																					domain.to_customer.top_20,
																					domain.ranking_data.top_20,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_20") &&
																		getValue(domain, "ranking_data.top_20") &&
																		domain.ranking_data.top_20 > 0
																			? domain.ranking_data.top_20
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_20 > 0
																					? domain.to_customer.top_20
																					: "-"
																				: domain.to_customer.kpi_6_month_top_20 > 0
																				? domain.to_customer.kpi_6_month_top_20
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>
												<div v-else>-</div>

												<template v-if="domain.to_delhi || domain.ranking_data">
													<v-tooltip top>
														<template #activator="{ on, attrs }">
															<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_20,
																					domain.to_customer.top_20,
																					domain.ranking_data.top_20,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_20") &&
																		getValue(domain, "ranking_data.top_20") &&
																		domain.ranking_data &&
																		domain.ranking_data.top_20 > 0
																			? domain.ranking_data.top_20
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_delhi
																			? domain.month_passed > 6
																				? domain.to_delhi.top_20 > 0
																					? domain.to_delhi.top_20
																					: "-"
																				: domain.to_delhi.kpi_6_month_top_20 > 0
																				? domain.to_delhi.kpi_6_month_top_20
																				: "-"
																			: "-"
																	}}
																</span>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>
												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'top_50'">
											<div
												v-if="domain.to_customer || domain.to_delhi || domain.ranking_data"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.to_customer || domain.ranking_data">
													<v-tooltip top="">
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_50,
																					domain.to_customer.top_50,
																					domain.ranking_data.top_50,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_50") &&
																		getValue(domain, "ranking_data.top_50") &&
																		domain.ranking_data &&
																		domain.ranking_data.top_50 > 0
																			? domain.ranking_data.top_50
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_50 > 0
																					? domain.to_customer.top_50
																					: "-"
																				: domain.to_customer.kpi_6_month_top_50 > 0
																				? domain.to_customer.kpi_6_month_top_50
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>

												<template v-if="domain.to_delhi || domain.ranking_data">
													<v-tooltip top="">
														<template #activator="{ on, attrs }">
															<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_50,
																					domain.to_customer.top_50,
																					domain.ranking_data.top_50,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_50") &&
																		getValue(domain, "ranking_data.top_50") &&
																		domain.ranking_data.top_50 > 0
																			? domain.ranking_data.top_50
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_delhi
																			? domain.month_passed > 6
																				? domain.to_delhi.top_50 > 0
																					? domain.to_delhi.top_50
																					: "-"
																				: domain.to_delhi.kpi_6_month_top_50 > 0
																				? domain.to_delhi.kpi_6_month_top_50
																				: "-"
																			: "-"
																	}}
																</span>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>
												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'top_100'">
											<div
												v-if="domain.to_customer || domain.to_delhi || domain.ranking_data"
												class="d-flex justify-center flex-column"
											>
												<template v-if="domain.to_customer || domain.ranking_data">
													<v-tooltip top="">
														<template #activator="{ on, attrs }">
															<div v-on="on" v-bind="attrs" class="d-flex justify-center">
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? (domain.to_customer.kpi_6_month_top_100,
																			  domain.to_customer.top_100,
																			  domain.ranking_data.top_100,
																			  domain.month_passed)
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_100") &&
																		getValue(domain, "ranking_data.top_100") &&
																		domain.ranking_data.top_100 > 0
																			? domain.ranking_data.top_100
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_customer ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_customer
																			? domain.month_passed > 6
																				? domain.to_customer.top_100 > 0
																					? domain.to_customer.top_100
																					: "-"
																				: domain.to_customer.kpi_6_month_top_100 > 0
																				? domain.to_customer.kpi_6_month_top_100
																				: "-"
																			: "-"
																	}}
																</span>
															</div>
														</template>
														<span>To Customer</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>

												<template v-if="domain.to_delhi || domain.ranking_data">
													<v-tooltip top="">
														<template #activator="{ on, attrs }">
															<v-chip v-on="on" v-bind="attrs" x-small label color="lime darken-4" outlined>
																<span
																	v-if="domain.ranking_data"
																	:class="[
																		domain.to_customer
																			? kpiColorIdentifier(
																					domain.to_customer.kpi_6_month_top_100,
																					domain.to_customer.top_100,
																					domain.ranking_data.top_100,
																					domain.month_passed
																			  )
																			: '',
																	]"
																>
																	{{
																		hasKey(domain, "ranking_data.top_100") &&
																		getValue(domain, "ranking_data.top_100") &&
																		domain.ranking_data.top_100 > 0
																			? domain.ranking_data.top_100
																			: "-"
																	}}
																</span>
																<span>{{ domain.ranking_data || domain.to_delhi ? "/" : "" }}</span>
																<span class="green--text">
																	{{
																		domain.to_delhi
																			? domain.month_passed > 6
																				? domain.to_delhi.top_100 > 0
																					? domain.to_delhi.top_100
																					: "-"
																				: domain.to_delhi.kpi_6_month_top_100 > 0
																				? domain.to_delhi.kpi_6_month_top_100
																				: "-"
																			: "-"
																	}}
																</span>
															</v-chip>
														</template>
														<span>To Delhi</span>
													</v-tooltip>
												</template>

												<div v-else>-</div>
											</div>
											<div v-else class="text-center">-</div>
										</template>

										<template v-if="th.key == 'keyword'">
											<div class="d-flex">
												<div
													class="brown darken-1 rounded-4 white--text d-flex justify-center align-center"
													style="border-radius: 245px; height: 22px; width: 22px"
												>
													{{ domain.total_keyword }}
												</div>
												<div class="ml-3 font-level-1-bold" style="font-weight: 600">Total</div>
											</div>
											<div class="d-flex mt-1">
												<!-- <div
													class="rounded-4 white--text d-flex justify-center align-center"
													style="border-radius: 245px; height: 22px; width: 22px; background: rgb(138, 228, 73)"
												>
													{{ domain.month_6 }}
												</div> -->

												<div
													class="rounded-4 white--text d-flex justify-center align-center font-level-1-bold"
													:class="[idnfColor(domain)]"
													style="border-radius: 245px; height: 22px; width: 33px"
												>
													6 M
												</div>
												<!-- <div
															style="background: rgb(138, 228, 73); border-radius: 99px"
															class="px-2 rounded-4 white--text"
														>
															{{ domain.month_6 }}
														</div> -->
												<!-- <ShowValue :object="domain" object-key="month_6" label="6 Month Keyword"></ShowValue> -->
											</div>

											<div class="d-flex mt-1">
												<!-- <ShowValue
											:object="domain"
											object-key="month_12"
											label="12 Month Keyword"
										></ShowValue> -->
												<!-- <div class="px-2 orange darken-3 rounded-4 white--text" style="border-radius: 99px">
											{{ domain.month_6 }}
										</div> -->
												<!-- <div
													class="rounded-4 orange darken-3 white--text d-flex justify-center align-center"
													style="border-radius: 245px; height: 22px; width: 22px"
												>
													{{ domain.month_12 }}
												</div> -->

												<div
													class="rounded-4 white--text font-level-1-bold d-flex justify-center align-center"
													style="border-radius: 245px; height: 22px; width: 33px"
													:class="[idnfColor(domain)]"
													v-if="domain.month_passed > 6"
												>
													12 M
												</div>
											</div>
										</template>

										<template v-if="th.key == 'executive'">
											<div class="d-flex">
												<div class="mr-1 teal--text text--darken-4" style="font-weight: 600; width: 60px">
													On Page:
												</div>
												<!-- <ShowValue :object="domain" object-key="on_page" label="On Page"></ShowValue> -->
												<ImageTemplate
													v-if="
														hasKey(domain, 'on_page.profile_img') &&
														getValue(domain, 'on_page.profile_img') &&
														domain.on_page.profile_img
													"
													circle
													style="max-width: 20px"
													:src="domain.on_page && domain.on_page.profile_img"
												>
												</ImageTemplate>

												<span class="text-capitalize" v-if="getValue(domain, 'on_page.display_name')">{{
													domain.on_page.display_name
												}}</span>
												<template v-else><em class="text-muted">no On Page</em></template>
											</div>
											<div class="d-flex">
												<div class="mr-1 brown--text text--darken-4" style="font-weight: 600; width: 60px">
													Off Page:
												</div>
												<!-- <ShowValue :object="domain" object-key="off_page" label="Off Page"></ShowValue> -->
												<ImageTemplate
													v-if="
														hasKey(domain, 'off_page.profile_img') &&
														getValue(domain, 'off_page.profile_img') &&
														domain.off_page.profile_img
													"
													circle
													style="max-width: 20px"
													:src="domain.off_page && domain.off_page.profile_img"
												>
												</ImageTemplate>
												<span class="text-capitalize" v-if="getValue(domain, 'off_page.display_name')"
													>{{ domain.off_page.display_name }}
												</span>
												<template v-else><em class="text-muted">no Off Page</em></template>
											</div>

											<div class="d-flex">
												<div
													class="mr-1 light-blue--text text--darken-3"
													style="font-weight: 600; width: 60px; color: #61677a"
												>
													ATL:
												</div>
												<ImageTemplate
													v-if="getValue(domain, 'a_team_lead.profile_img') && domain.a_team_lead.profile_img"
													circle
													style="max-width: 20px"
													:src="domain.a_team_lead && domain.a_team_lead.profile_img"
												>
												</ImageTemplate>

												<span
													class="text-capitalize"
													v-if="getValue(domain, 'a_team_lead.display_name') && domain.a_team_lead.display_name"
													>{{ domain.a_team_lead.display_name }}
												</span>
												<template v-else><em class="text-muted">no ATL</em></template>
											</div>
										</template>

										<template v-if="th.key == 'reporting'">
											<template v-if="domain?.reporting_date">
												<!-- <v-chip label outlined color="indigo darken-4">
													<span class="fw-600">{{ domain?.reporting_date }}</span>
												</v-chip> -->
												<div v-if="domain?.reporting_date > 1" class="fw-600">
													{{ domain?.reporting_date }}th <span class="fw-400"> of every month.</span>
												</div>
												<div v-else class="fw-600">
													{{ domain?.reporting_date }}st <span class="fw-400"> of every month.</span>
												</div>
											</template>
											<em v-else class="text-muted">no reporting date</em>
										</template>

										<template v-if="th.key == 'status'">
											<v-tooltip v-if="domain.progress_status" right>
												<template #activator="{ attrs, on }">
													<div class="d-flex align-center justify-content-between" v-on="on" v-bind="attrs">
														<!-- <v-icon color="gray" class="mr-1">mdi-finance</v-icon> -->
														<div class="mr-2 fw-500">Progress :</div>
														<v-chip
															class="mb-1 mr-1 px-1 py-0 text-capitalize"
															label
															dark
															:color="getStatusColor(domain.progress_status)"
														>
															{{ domain.progress_status }}
														</v-chip>
													</div>
												</template>
												Progress Status
											</v-tooltip>
											<v-tooltip right class="mt-1">
												<template #activator="{ attrs, on }">
													<div class="d-flex align-center justify-content-between" v-on="on" v-bind="attrs">
														<!-- <v-icon color="gray" class="mr-1">mdi-finance</v-icon> -->
														<div class="mr-2 fw-500">Project :</div>
														<v-chip
															class="mb-1 mr-1 px-1 py-0 text-capitalize"
															label
															dark
															:color="project_status[domain.status].color"
														>
															{{ project_status[domain.status].text }}
														</v-chip>
													</div>
												</template>
												Project Status
											</v-tooltip>

											<div
												class="d-flex mt-1"
												v-if="getValue(domain, 'request_status') && getValue(domain, 'request_status') > 0"
											>
												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-chip
															x-small
															class="mr-1 text-capitalize px-1"
															v-on="on"
															style="height: 24px !important; width: 25px; border-radius: 50%"
															v-bind="attrs"
															:color="ApprovalStatus[domain.team_lead_approve].color"
														>
															<span style="font-size: 1rem !important" class="white--text fw-600">TL</span>
														</v-chip>
													</template>
													<span>Team Lead ({{ ApprovalStatus[domain.team_lead_approve].title }})</span>
												</v-tooltip>

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-chip
															x-small
															class="mr-1 text-capitalize pl-2 pr-1"
															v-on="on"
															style="height: 24px !important; width: 25px; border-radius: 50%"
															v-bind="attrs"
															:color="ApprovalStatus[domain.sales_owner_approve].color"
														>
															<span style="font-size: 1rem !important" class="white--text fw-600">S</span>
														</v-chip>
													</template>
													<span>Sales Owner ({{ ApprovalStatus[domain.sales_owner_approve].title }})</span>
												</v-tooltip>

												<v-tooltip top>
													<template #activator="{ on, attrs }">
														<v-chip
															x-small
															class="text-capitalize pl-2 pr-1"
															style="height: 24px !important; width: 25px; border-radius: 50%"
															v-on="on"
															v-bind="attrs"
															:color="ApprovalStatus[domain.account_approve].color"
														>
															<span style="font-size: 1rem !important" class="white--text fw-600">A</span>
														</v-chip>
													</template>
													<span>Accounts ({{ ApprovalStatus[domain.account_approve].title }})</span>
												</v-tooltip>
											</div>
										</template>

										<template v-if="th.key == 'remark'">
											<div class="d-flex">
												<div
													class="line-clamp-1"
													style="
														max-width: 300px;
														text-wrap: initial;
														display: -webkit-box;
														-webkit-line-clamp: 1;
														-webkit-box-orient: vertical;
														overflow: hidden;
													"
												>
													<ShowTextAreaValue :object="domain" object-key="remark" label="remark">
													</ShowTextAreaValue>
												</div>
												<v-icon class="ml-1 inline" small color="green">mdi-chat</v-icon>
											</div>
										</template>

										<template v-if="th.key == 'category'">
											<v-chip v-if="domain.category" label color="cyan darken-4" outlined>
												{{ mod_string(domain.category, "_", " ") }}
											</v-chip>
											<em v-else class="text-muted">no category</em>
										</template>

										<template v-if="th.key == 'task'">
											<ShowValue :object="domain" object-key="task" label="task"></ShowValue>
										</template>

										<template v-if="th.key == 'avg_ranking'">
											<v-chip label outlined v-if="domain.average_rank" color="primary">
												{{ domain.average_rank.toFixed(2) }}
												<!-- <ShowValue :object="domain" object-key="average_rank" label="Average Ranking">
												</ShowValue> -->
											</v-chip>
											<em v-else class="text-muted">no Average Ranking</em>
										</template>

										<template v-if="th.key == 'backlink_count'">
											<ShowValue :object="domain" object-key="backlink_count" label="Backlink"> </ShowValue>
										</template>

										<template v-if="th.key == 'contract'">
											<div class="position-relative" style="display: grid; place-items: center">
												<table class="w-100">
													<tr>
														<td style="border-right: none !important" class="fw-600">Start</td>
														<td class="text-end">
															<v-chip
																label
																outlined
																class="px-1"
																color="orange darken-4"
																v-if="domain.select_date"
															>
																{{ formatDate(domain.select_date) }}
															</v-chip>
															<em v-else class="text-muted">no start date</em>
														</td>
													</tr>
													<tr>
														<td style="border-right: none !important" class="fw-600">End</td>
														<td class="text-end">
															<v-chip
																label
																outlined
																class="px-1"
																color="indigo darken-4"
																v-if="domain.select_date"
															>
																{{ formatDate(domain.total_time_end) }}
															</v-chip>
															<em v-else class="text-muted">no end date</em>
														</td>
													</tr>
													<tr v-if="domain.time_free_end">
														<td style="border-right: none !important" class="fw-600">Extend</td>
														<td class="text-end">
															<v-chip label class="px-1" outlined color="green darken-4" v-if="domain.select_date">
																{{ formatDate(domain.time_free_end) }}
															</v-chip>
															<em v-else class="text-muted">no extend date</em>
														</td>
													</tr>
												</table>
												<div
													v-if="
														date_difference(
															formatDate_Raw(new Date()),
															domain.time_free_end ? domain.time_free_end : domain.total_time_end
														) < 0
													"
													class="h2 tilted_watermark mb-0 red--text p-2 position-absolute rounded text-center"
												>
													Expired
												</div>
											</div>
										</template>

										<template v-if="th.key == 'time_left'">
											<ShowValue :object="domain" object-key="time_left" label="Time Left"> </ShowValue>
										</template>

										<template v-if="th.key == 'created_at'">
											<Chip
												class="mr-1"
												outlined
												:text="domain.created_at"
												textColor="green"
												color="green"
											></Chip>
										</template>
									</td>
								</template>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td colspan="20">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no projects at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</table>
			</div>
		</div>
		<!-- </div> -->

		<v-row v-if="totalPage">
			<v-col md="6" class="my-auto">
				<p class="m-0">{{ showingString }}</p>
			</v-col>
			<v-col md="6" class="text-right">
				<v-pagination
					color="blue darken-4"
					total-visible="9"
					v-model="page"
					:length="totalPage"
					@input="updatePagination($event)"
				></v-pagination>
			</v-col>
		</v-row>

		<!-- <v-pagination class="listing-pagination" :length="totalPage" v-model="currentPage"> </v-pagination> -->

		<DeleteTemplate
			v-if="deleteDialog"
			type="SEO Project"
			delete-text="SEO Project"
			v-on:success="successDeleteSEOProject"
			v-on:close="deleteDialog = false"
			:delete-dialog="deleteDialog"
			delete-url="seo/project/keyword-analysis/${id}?type=keyword_analysis"
		>
		</DeleteTemplate>

		<!-- v-on:success="successDeleteSEOProject" -->
		<UpdateSEO
			v-if="editDialog"
			v-on:close="editDialog = false"
			v-on:success="successUpdateSEOProject"
			:edit-dialog="editDialog"
			:seo-id="seoId"
		>
		</UpdateSEO>

		<!-- UNRESOLVED DOMAINS -->
		<BottleNeckUrls
			@close="closeBottleNeck"
			@open="statusDialog = true"
			v-on:success="successUpdateSEOProject"
			:status-dialog="statusDialog"
			v-if="
				isMaster == 0 ||
				(userType != 'sales' &&
					userType != 'accounts' &&
					currentUser.role != 6 &&
					currentUser.role != 7)
			"
		>
		</BottleNeckUrls>
	</div>
	<!-- </v-sheet> -->
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import MainMixin from "@/core/mixins/main.mixin";
import HeightMixin from "@/core/mixins/height.mixin";
import { mapGetters } from "vuex";
import { SET_TBODY } from "@/core/services/store/listing.module";
import {
	SET_SELECTED_ROWS,
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_COUNT,
	SET_TOTAL_PAGE,
} from "@/core/services/store/listing.module";
import { QUERY, GET } from "@/core/services/store/request.module";
import objectPath from "object-path";
import { toSafeInteger } from "lodash";

export default {
	name: "SeoExecutive",
	mixins: [ListingMixin, HeightMixin, MainMixin],
	components: {
		SelectInput: () => import("@/view/components/SelectInput"),
		DateRangePicker: () => import("@/view/components/DateRangePickerV2"),
		ImageTemplate: () => import("@/view/components/Image"),
		Chip: () => import("@/view/components/Chip"),
		ShowTextAreaValue: () => import("@/view/components/ShowTextAreaValue"),
		ShowValue: () => import("@/view/components/ShowValue"),
		DeleteTemplate: () => import("@/view/components/DeleteTemplate"),
		UpdateSEO: () => import("@/view/components/UpdateSEOProject"),
		BottleNeckUrls: () => import("@/view/components/SEO-BottleNeckUrls"),
	},
	data() {
		return {
			skipBottomHeight: 56,
			statusFilterData: [],
			pageTitle: "SEO Project",
			query: null,
			page: 1,
			endpoint: "seo",
			permissions: ["seo:update", "seo:delete"],
			deleteDialog: false,
			checkbox: false,
			categoryList: [],
			project: "",
			status: null,
			date_range: [],
			seoTeam: [],
			customFilter: {
				entity: null,
				status: null,
				user_filter: null,
				daterange: [],
				category: null,
				current_page: 1,
				search: null,
				seo_team: null,
				alphabet: null,
			},
			usersList: [],
			sale_person: "",
			seoProjectList: [],
			entityList: [
				{
					text: "BThrust",
					value: "bthrust",
				},
				{
					text: "Genic Solution",
					value: "genic-solution",
				},
			],
			projectCreateDialog: false,
			notification_on: "Select Notification On...",
			domainStatusList: [
				{ text: "Expiry", value: 0, color: "red" },
				{ text: "Expiry in 3 Month", value: 1, color: "orange" },
			],
			projectStatus: 0,
			contact_person: null,
			invoice_number: null,
			notification: null,
			toggle_exclusive: null,
			projectStatusList: [
				{
					id: 192,
					text: "All SEO",
					value: null,
					type: 40,
					status_color: "blue",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Active",
					value: "1",
					type: 40,
					status_color: "green",
					count: 0,
					status_count: 0,
				},
				{
					id: 193,
					text: "Expired",
					value: "2",
					type: 40,
					status_color: "red",
					count: 0,
					status_count: 0,
				},
				{
					id: 194,
					text: "Expire in 3 Month",
					value: "3",
					type: 40,
					status_color: "orange",
					count: 0,
					status_count: 0,
				},
			],
			filter: [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
				"k",
				"l",
				"m",
				"n",
				"o",
				"p",
				"q",
				"r",
				"s",
				"t",
				"u",
				"v",
				"w",
				"x",
				"y",
				"z",
			],
			id: null,
			selectedRows: [],
			indeterminate: false,
			editDialog: false,
			seoId: null,
			colorCount: 0,
			statusDialog: false,
			project_status: {
				1: { text: "Active", color: "green" },
				2: { text: "Hold", color: "orange" },
				3: { text: "Closed", color: "red" },
			},
			ApprovalStatus: {
				0: { title: "Pending", color: "orange darken-1" },
				1: { title: "Approved", color: "green" },
				2: { title: "Rejected", color: "red" },
			},
		};
	},
	methods: {
		closeBottleNeck() {
			this.statusDialog = false;
			this.getListing();
		},
		getValue(object, path) {
			return objectPath.get(object, path);
		},
		hasKey(object, path) {
			return objectPath.has(object, path);
		},
		/* ApprovalStatus(apprvl) {
			switch (apprvl) {
				case 1:
					return "Approved";
				case 2:
					return "Rejected";
				case 3:
					return "Pending";
			}
		}, */
		kpiColorIdentifier(half_yr, full_yr, current, month_passed) {
			let color;
			let monthPassed = month_passed;
			let kpi_value;

			if (monthPassed <= 7) {
				kpi_value = half_yr * (monthPassed / 6);
			} else {
				kpi_value = full_yr * (monthPassed / 12);
			}

			if (Math.ceil(kpi_value) <= current) {
				color = "green--text";
			} else {
				color = "red--text";
			}

			return color;
		},
		updatePageData() {
			return Math.ceil(this.seoProjectList.length / this.itemsPerPage);
		},
		getStatusColor(status) {
			if (status == "expired") {
				return "red";
			} else if (status == "Expire In 1 Month") {
				return "orange";
			} else if (status == "active") {
				return "green";
			}
		},
		goToSeoProjectDetail(th, domain) {
			if (th.checkbox == false && th.key != "action") {
				let query = this.$route.query;
				this.$router.push({
					name: `seo-detail`,
					query: { ...query },
					params: {
						id: domain.id,
						// sales: domain.sales_owner.display_name
					},
				});
			}
		},
		successDeleteSEOProject() {
			console.log("OKKKKKKKKKKKK");
			// this.$store.commit(SET_TBODY, data.tbody);
		},
		successUpdateSEOProject() {
			this.editDialog = false;
			this.filterRows();
			// this.statusFilterData()
		},
		resetFilters() {
			this.status = null;
			this.customFilter = {
				category: null,
				entity: null,
				user_filter: null,
				daterange: null,
				search: null,
				alphabet: null,
			};
			this.filter_user = 0;
			this.statusFilterRows();
		},
		doAction(row, param) {
			switch (param) {
				case "download":
					window.open(row.url, "_blank");
					break;
				case "delete":
					this.id = row.id;
					this.deleteDialog = true;
					break;
			}
		},
		filterDate() {
			if (this.customFilter.daterange == null || this.customFilter.daterange.length > 1) {
				this.statusFilterRows();
			}
		},
		statusFilterRows() {
			this.allFiltersStatus();

			const PAYLOAD = {
				status: this.status || null,
				user_filter: this.customFilter.user_filter || null,
				daterange: this.customFilter.daterange || null,
				// current_page: this.customFilter.current_page,
				search: this.customFilter.search || null,
				entity: this.customFilter.entity || null,
				category: this.customFilter.category || null,
				alphabet: this.customFilter.alphabet || null,
				seo_team: this.customFilter.seo_team || null,
			};

			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `seo`,
					data: PAYLOAD,
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_COUNT, data.count);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus() {
			this.$router.push({
				name: "seo",
				query: {
					status: this.status || null,
					user_filter: this.customFilter.user_filter || null,
					daterange: this.customFilter.daterange || null,
					current_page: this.customFilter.current_page || null,
					// search: this.customFilter.search,
					category: this.customFilter.category || null,
					entity: this.customFilter.entity || null,
					alphabet: this.customFilter.alphabet || null,
					seo_team: this.customFilter.seo_team || null,
				},
			});
		},
		selectAll() {
			this.selectedRows = [];
			if (this.checkbox) {
				for (let i = 0; i < this.tbody.length; i++) {
					this.selectedRows.push(this.tbody[i].id);
				}
			}
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		selectRow() {
			this.$store.commit(SET_SELECTED_ROWS, this.selectedRows);
		},
		updatePagination(param) {
			if (this.currentPage == param) {
				return false;
			}
			this.$store.commit(SET_CURRENT_PAGE, param);
			this.$nextTick(() => {
				this.filterRows();
			});
		},
		editSingleData(data) {
			this.seoId = data.id;
			this.editDialog = true;
		},
		getSettings() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "lead-setting" })
				.then((data) => {
					if (data && data.users && Array.isArray(data.users)) {
						const Sales_Users = data.users.filter((user) => {
							if ("users_type" in user && user.users_type == "sales") {
								return user;
							}
						});
						_this.usersList = Sales_Users.length ? Sales_Users : /* data.users || */ [];
					}
					_this.categoryList = data.category || [];
				})
				.catch((error) => {
					console.log({ error });
				});
			/* .finally(() => {
					_this.pageLoading = false;
				}); */
		},
		target_flag(data) {
			let target = data.target_urls ? data.target_urls.value : null;
			return target
				? target == "google-singapore"
					? "sg"
					: target == "google-malaysia"
					? "my"
					: "us"
				: "";
		},
		mod_string(text, saperator = "", joiner = "") {
			let splitArr = text ? text.split(saperator) : [];
			let mod_arr = splitArr.map((v) => v.charAt(0).toUpperCase() + v.slice(1));
			return mod_arr.join(joiner);
		},
		idnfColor(data) {
			let monthPassed = data.month_passed;
			let final_value = 0;
			let ranking_data = data.ranking_data;
			let to_customer = data.to_customer;

			if (ranking_data && to_customer) {
				if (monthPassed <= 7) {
					if (Math.ceil(to_customer?.kpi_6_month_top_3 * (monthPassed / 6)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_5 * (monthPassed / 6)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_10 * (monthPassed / 6)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_20 * (monthPassed / 6)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_50 * (monthPassed / 6)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.kpi_6_month_top_100 * (monthPassed / 6)) <= ranking_data.top100) {
						final_value += 1;
					}
				} else {
					if (Math.ceil(to_customer?.top_3 * (monthPassed / 12)) <= ranking_data.top3) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_5 * (monthPassed / 12)) <= ranking_data.top5) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_10 * (monthPassed / 12)) <= ranking_data.top10) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_20 * (monthPassed / 12)) <= ranking_data.top20) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_50 * (monthPassed / 12)) <= ranking_data.top50) {
						final_value += 1;
					}
					if (Math.ceil(to_customer?.top_100 * (monthPassed / 12)) <= ranking_data.top100) {
						final_value += 1;
					}
				}
			}

			return final_value < 6 ? "red" : "green";
		},
		getSeoTeams() {
			const _this = this;
			_this.$store
				.dispatch(GET, { url: "user-role" })
				.then((data) => {
					if (data.all_seo_users.length) this.seoTeam = data.all_seo_users || [];
				})
				.catch((error) => {
					console.log({ error });
				});
			/* .finally(() => {
					_this.pageLoading = false;
				}); */
		},
	},
	computed: {
		...mapGetters([
			"selected",
			"tbody",
			"count",
			"currentPage",
			"currentUser",
			"totalPage",
			"showingString",
			"thead",
			"sortedThead",
			"layoutConfig",
			"isMaster",
			"userType",
		]),
		checkAll: {
			get() {
				return this.tbody.length > 0
					? this.selectedRows.length == this.tbody.length
						? true
						: false
					: false;
			},
			set(value) {
				if (value) {
					let _selectedRows = this.tbody.map((v) => v.id);
					this.selectedRows = [..._selectedRows];
					this.$store.commit(SET_SELECTED_ROWS, [..._selectedRows]);
				} else {
					this.selectedRows = [];
					this.$store.commit(SET_SELECTED_ROWS, []);
				}
			},
		},
	},
	watch: {
		selected(param) {
			if (param.length < 1) {
				this.checkbox = false;
				this.indeterminate = false;
				this.selectedRows = [];
			}
		},
		currentPage(param) {
			this.page = param;
		},
		count(param) {
			let allIndex = this.projectStatusList.findIndex((v) => v.value == null);
			let activeIndex = this.projectStatusList.findIndex((v) => v.value == 1);
			let expireIndex = this.projectStatusList.findIndex((v) => v.value == 2);
			let quaterIndex = this.projectStatusList.findIndex((v) => v.value == 3);

			if (allIndex > -1) {
				this.projectStatusList[allIndex].count = param.total;
			}
			if (activeIndex > -1) {
				this.projectStatusList[activeIndex].count = param.total_active;
			}
			if (expireIndex > -1) {
				this.projectStatusList[expireIndex].count = param.total_expire;
			}
			if (quaterIndex > -1) {
				this.projectStatusList[quaterIndex].count = param.total_expire_3_month;
			}
		},
	},
	mounted() {
		this.getSettings();
		this.getSeoTeams();
		/* this.customFilter.current_page = this.$route.query.current_page;
		this.getCustomerStatus();
		LeaveEventBus.$on("refresh", () => {
			this.getListing();
		}); */
	},
	beforeMount() {
		const { /* name, params, */ query } = this.$route;
		if (Object.keys(query).length > 1) {
			this.customFilter.alphabet = query.alphabet || null;
			this.customFilter.category = query.category || null;
			this.customFilter.current_page = query.current_page || 1;
			this.customFilter.entity = query.entity || null;
			this.customFilter.user_filter = +query.user_filter || null;
			this.customFilter.daterange = query.daterange || null;
			this.status = query.status || null;
			this.customFilter.seo_team = +query.seo_team || null;
		}

		// this.$router.push({ name, params, query: { ...query, user_filter: null } });
		this.customFilter.user_filter = toSafeInteger(this.$route.query.user_filter);
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.customFilter.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
	},
};
</script>

<style>
.bTable_height {
	height: calc(100vh - 233px) !important;
}
</style>
