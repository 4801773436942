import { render, staticRenderFns } from "./SeoProject.vue?vue&type=template&id=c5f22ad8"
import script from "./SeoProject.vue?vue&type=script&lang=js"
export * from "./SeoProject.vue?vue&type=script&lang=js"
import style0 from "@/assets/sass/sprite.scss?vue&type=style&index=0&prod&lang=scss&external"
import style1 from "./SeoProject.vue?vue&type=style&index=1&id=c5f22ad8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports